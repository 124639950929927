// scrollbar tinkering

@supports (scrollbar-width: thin) {
  * {
    scrollbar-width: thin;
    scrollbar-color: #444 transparent;
  }
}

::-webkit-scrollbar {
  width: .9em;
}

::-webkit-scrollbar-track {
  background: var(--cui-body-bg, #fff);
  border-radius: 100vw;
  margin-block: .2em;
}

::-webkit-scrollbar-thumb {
  background: #999;
  border: .2em solid var(--cui-body-bg, #fff);
  border-radius: 100vw;
}

::-webkit-scrollbar-thumb:hover {
  background: #444;
}

.dark-theme::-webkit-scrollbar-thumb {
  background: var(--cui-gray-600, #444);
}

.dark-theme::-webkit-scrollbar-thumb:hover {
  background: var(--cui-gray-400, #999);
}
